<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF aC">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
				</div>
				<a-tab-pane key="1" tab="Overview">
					<a-card class="table-card mb-4">
						<a-table class="tableStyle" :columns="columns" :data-source="campaigns" :row-key="(record) => record.id">
							<div slot="actions" slot-scope="record" class="text-center">
								<a-button v-if="selectedCampaign == record.name" type="primary">SELECTED</a-button>
								<a-button v-else @click="selectedCampaign = record.name; getCampaignData()">SELECT</a-button>
							</div>
						</a-table>
					</a-card>
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="!error" class="report-grid">
								<div v-for="(item, index) in metricsData" :key="index" class="html2pdf__page-break">
									<a-card :bordered="false" :loading="loading">
										<div data-html2canvas-ignore="true" class="dF aC jE">
											<span class="mr-2">Chart Type:</span>
											<a-select v-model="metricDataChartType[item.label]" style="width: 80px"
												class="text-primary" :showArrow="true">
												<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />
												<a-select-option value="bar" class="text-primary">
													Bar
												</a-select-option>
												<a-select-option value="line" class="text-primary">
													Line
												</a-select-option>
												<a-select-option value="pie" class="text-primary">
													Pie
												</a-select-option>
											</a-select>
										</div>
										<eChartLine :option="chartOptions[item.label]" :key="'title'" />
									</a-card>
								</div>
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="getCampaignData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>

		</div>
	</div>
</template>

<script>
import eChartLine from "@/components/charts/eChartLine";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import VueHtml2pdf from "vue-html2pdf";
import { mapState } from "vuex";

export default {
	components: {
		eChartLine,
		PDFOrientation,
		VueHtml2pdf
	},
	data: () => ({
		loading: false,
		error: false,
		selectedCampaign: '',
		columns: [
			{
				title: "Campaign Name",
				dataIndex: "name",
				key: "name",
				sorter: (a, b) =>
					a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
			},
			{
				title: "Total Leads",
				dataIndex: "count",
				key: "count",
				sorter: (a, b) =>
					a.count - b.count,
			},
			{
				title: "",
				scopedSlots: { customRender: "actions" },
				key: "actions",
			},
		],
		chartTitles: [
			'Source Metric Campaign',
			'Term Metric Campaign',
			'Medium Metric Campaign',
			'Content Metric Campaign',
		],
		metrics: ['source', 'term', 'medium', 'content'],
		metricDataChartType: {},
		metricsData: [],
		basicOptions: {
			title: {
				text: '',
				left: 'center'
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
			legend: {
				top: 'bottom'
			},
			xAxis: {
				type: 'category',
				data: []
			},
			yAxis: {
				type: 'value'
			},
			series: [
				{
					name: '',
					type: 'bar',
					data: [],
					colorBy: 'data',
					radius: '50%',
					lineStyle: {
						color: "#f7941e"
					},
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}
			]
		}
	}),

	computed: {
		...mapState(["selectedChart", "records", "instance"]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		campaigns() {
			return (this.records.campaigns || []).map((campaign, index) => {
				return {
					...campaign,
					id: index
				}
			})
		},

		chartOptions() {
			let campaignOptions = {};
			this.metricsData.map(item => {
				let options = JSON.parse(JSON.stringify(this.basicOptions));

				options.title.text = item.label;
				options.title.textStyle = {
					fontSize: 18,
					width: 400,
					overflow: "break",
					rich: {
						wrap: {
							width: 400,
							overflow: "break"
						}
					}
				}
				options.tooltip.trigger = this.metricDataChartType[item.label] === 'pie' ? 'item' : 'axis'
				options.series[0].type = this.metricDataChartType[item.label];

				if (this.metricDataChartType[item.label] === 'pie') {
					options.series[0] = {
						...options.series[0],
						data: item.stats.map(s => { return { value: s.count, name: s.metric } }),
					}
				} else {
					options.xAxis.data = item.stats.map(s => s.metric);
					options.series[0] = {
						...options.series[0],
						data: item.stats.map(s => s.count)
					}
				}

				campaignOptions[item.label] = options;
			})

			return campaignOptions;
		},

		filename() {
			return `Lead Report - ${this.selectedCampaign} Campaign`;
		}
	},

	created() {
		if (this.campaigns && this.campaigns.length) {
			this.selectedCampaign = this.campaigns[0].name;
			this.getCampaignData();
		}
	},

	methods: {
		async getCampaignData() {
			this.loading = true;
			try {
				let promises = [
					this.$api.get(`traffic-reports/:instance/campaign-report-for-metric?campaign=${this.selectedCampaign}&metric=source`),
					this.$api.get(`traffic-reports/:instance/campaign-report-for-metric?campaign=${this.selectedCampaign}&metric=term`),
					this.$api.get(`traffic-reports/:instance/campaign-report-for-metric?campaign=${this.selectedCampaign}&metric=medium`),
					this.$api.get(`traffic-reports/:instance/campaign-report-for-metric?campaign=${this.selectedCampaign}&metric=content`),
				]
				const result = await Promise.all(promises)
				this.metricsData = []
				result.forEach((r, index) => {
					this.$set(this.metricDataChartType, this.chartTitles[index], 'bar')
					this.metricsData.push({
						label: this.chartTitles[index],
						stats: r.data.trafficForCampaignByMetric
					})
				})
			} catch (error) {
				this.error = true;
				console.error(error);
			}
			this.loading = false;
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);

						pdf.setFontSize(16);

						const title = this.chartTitles[i - 1] || ''
						pdf.text(
							title,
							(pdf.internal.pageSize.getWidth() * 0.44) - title.length,
							(this.pdfOrientation === 'portrait' ? 25 : 10)
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}
</style>
